import { createSlice } from "@reduxjs/toolkit";

type storeType = {
    access: { id: string; authorization: string }[];
};

const initialState: storeType = {
    access: [{ id: "", authorization: "" }],
};

export const accessSlice = createSlice({
    name: "accessSlice",
    initialState,
    reducers: {
        setUserChildsAccess: (state: any, action: any) => {
            state.access = action.payload;
        },

        updateUserChildsAccess: (state: any, action: any) => {
            const { userAccessStatus, entityChildId } = action.payload;
            if (state.access.length > 0) {
                state.access = state.access.map((item: any) => {
                    if (item.id === entityChildId) {
                        return {
                            ...item,
                            authorization: userAccessStatus,
                        };
                    }
                    return item;

                });
            }
        },
    },
});

export const { updateUserChildsAccess, setUserChildsAccess } =
    accessSlice.actions;

export default accessSlice.reducer;
