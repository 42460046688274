import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import React from "react";
import Switch from "react-switch";

type ConsultColmun = {
    Data: any;
    selectedRow: any;
    setlistSelectedData: Function;
    editProfile: boolean;
};

function ConsultColumn({
    Data,
    selectedRow,
    setlistSelectedData,
    editProfile,
}: ConsultColmun) {
    const [ischeckedConsult, setIsCheckedConsult] = React.useState(false);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const SelectedProfil = dynamicTableWrapperContext?.rowData?.original;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function EditProfile() {
        if (SelectedProfil.autorisation[selectedRow.id].Consult === true) {
            if (ischeckedConsult === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult: false,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult: true,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            }
        } else if (ischeckedConsult === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Visible
                        : SelectedProfil.autorisation[selectedRow.id].Visible,
                Consult: true,
                Modifie:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Modifie
                        : SelectedProfil.autorisation[selectedRow.id].Modifie,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Visible
                        : SelectedProfil.autorisation[selectedRow.id].Visible,
                Consult: false,
                Modifie:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Modifie
                        : SelectedProfil.autorisation[selectedRow.id].Modifie,
            };
        }
    }
    function AddProfile() {
        if (ischeckedConsult === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Visible,
                Consult: true,

                Modifie:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Modifie,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Visible,
                Consult: false,
                Modifie:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Modifie,
            };
        }
    }

    return (
        <div className="d-flex align-items-center pfl_icn_enableDisableConsultation">
            <Switch
                onChange={() => {
                    setIsCheckedConsult(!ischeckedConsult);
                    if (editProfile) {
                        EditProfile();
                    } else {
                        AddProfile();
                    }

                    setlistSelectedData(Data);
                }}
                checked={
                    // eslint-disable-next-line no-nested-ternary
                    editProfile
                        ? SelectedProfil.autorisation[selectedRow.id]
                              .Consult === true
                            ? !ischeckedConsult
                            : ischeckedConsult
                        : ischeckedConsult
                }
                offColor="#E30613"
                onColor="#34C38F"
            />
        </div>
    );
}

export default ConsultColumn;
