/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";
import { PencilIcon } from "@aureskonnect/react-ui";

import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";

import { DynamicTableWrapperContext } from "@components/Common";
import { StatusActive } from "@constants/index";
import ConfirmBlockModal from "@components/Common/Modals/ConfirmBlockModal";
import ConfirmArchivedModal from "@components/Common/Modals/ConfirmArchivedModal";
import { ManageAccessModal } from "@components/Common/Modals/AccessManagment/ManageAccessModal";
import ActionColumn from "./ActionColumn";
import ActionCustonFilter from "./ActionCustonFilter";

import "./style.css";

export function UserAccountList(): JSX.Element {
    const { t } = useTranslation();
    const customerAccountId = localStorage.getItem("customerAccountId");

    const userAccountStatus = StatusActive;

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const isVisible = true;

    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const [isConfirmBlockModal, setIsConfirmBlockModal] = React.useState(false);
    const [isConfirmArchivedModal, setIsConfirmArchivedModal] =
        React.useState<boolean>(false);

    const [isManageAccessModalOpened, setIsManageAccessModalOpened] =
        React.useState<boolean>(false);

    function handleOnEditUser(user: any) {
        dynamicTableWrapperContext?.setRowData({ ...user });
    }

    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData({
            Nom: "",
            Prénom: "",
            Email: "",
            "Téléphone 1": 0,
            "Téléphone 2": 0,
            Profil: "",
            Balise: "",
            Commentaire: "",
        });

        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    const customJsxActions = (e: any) => (
        <div className="d-flex justify-content-around w-50">
            <div id={`Duplication${e?.selectedRow?.iuud}`}>
                <LockIconWithToolTip
                    message={t(`Duplicate`)}
                    id={`Duplication${e?.selectedRow?.iuud}`}
                />
            </div>

            <div
                id={`Modifier${e?.selectedRow?.iuud}`}
                className="cmn_icn_edit"
            >
                <LockIconWithToolTip
                    message={t(`Edit`)}
                    id={`Modifier${e?.selectedRow?.iuud}`}
                />
                <PencilIcon
                    style={{ cursor: "pointer" }}
                    height={25}
                    width={25}
                    onClick={() => {
                        handleOnEditUser(e.selectedRow);
                        dynamicTableWrapperContext?.setLocalFilterActive(false);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            true
                        );
                    }}
                />
            </div>
        </div>
    );

    function handleBlockAccountOnClickEvent(user: any) {
        setIsConfirmBlockModal(!isConfirmBlockModal);
        dynamicTableWrapperContext?.setRowData({ ...user });
    }

    function handleManageAccessOnClickEvent(selectedRow: any) {
        setIsManageAccessModalOpened(!isManageAccessModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRow });
    }

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: customJsxActions,
        },
    ];

    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/users?customerAccountUUid=${customerAccountId}&userAccountStatus=${userAccountStatus}`}
                canSort
                name="table-user"
                canResize
                showGlobalFilter
                showFilter
                canSelect
                customSelect
                setSelectedRows={setSelectedRows}
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <ActionColumn
                            e={e}
                            isConfirmArchivedModal={isConfirmArchivedModal}
                            // eslint-disable-next-line react/jsx-no-bind
                            handleManageAccessOnClickEvent={
                                handleManageAccessOnClickEvent
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleBlockAccountOnClickEvent={
                                handleBlockAccountOnClickEvent
                            }
                            setIsConfirmArchivedModal={
                                setIsConfirmArchivedModal
                            }
                        />
                    );
                }}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                customJsxSideFilterButton={
                    <ActionCustonFilter
                        handleSubmit={handleSubmit}
                        selectedRows={selectedRows}
                        // eslint-disable-next-line react/jsx-no-bind
                        handleManageAccessOnClickEvent={
                            handleManageAccessOnClickEvent
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        handleBlockAccountOnClickEvent={
                            handleBlockAccountOnClickEvent
                        }
                        setIsConfirmArchivedModal={setIsConfirmArchivedModal}
                        isConfirmArchivedModal={isConfirmArchivedModal}
                    />
                }
                setData={dynamicTableWrapperContext?.setDataTable}
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
            />
            <ConfirmBlockModal
                isOpen={isConfirmBlockModal}
                setIsOpen={setIsConfirmBlockModal}
                setSelectedRows={setSelectedRows}
            />
            <ConfirmArchivedModal
                isOpen={isConfirmArchivedModal}
                setIsOpen={setIsConfirmArchivedModal}
                setSelectedRows={setSelectedRows}
            />
            <ManageAccessModal
                isOpen={isManageAccessModalOpened}
                setIsOpen={setIsManageAccessModalOpened}
            />
        </React.Fragment>
    );
}
