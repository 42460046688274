import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";

type ArchivedActionColumnType = {
    e: any;
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmUnArchivedModal: boolean;
};


function ArchivedActionColumn({
    e,
    isConfirmUnArchivedModal,
    setIsConfirmUnArchivedModal,
}: ArchivedActionColumnType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="com_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    role="button"
                    tabIndex={0}
                    className={`car_btn_unarchiveAccount dropdown-item pl-1 ${
                        e.selectedRow.original.isAdmin === true
                            ? "disabled_clz"
                            : ""
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        dynamicTableWrapperContext?.setRowData({
                            ...e.selectedRow,
                        });
                        setIsConfirmUnArchivedModal(!isConfirmUnArchivedModal);
                    }}
                >
                    <UnArchiveIcon width={25} height={25} />
                    <span className="ml-2">{t("UnArchiver account")}</span>
                </div>
            </div>
        </div>
    );
}

export default ArchivedActionColumn;
