import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import React from "react";
import Switch from "react-switch";

type ConsultColmun = {
    Data: any;
    selectedRow: any;
    setlistSelectedData: Function;
    editProfile: boolean;
};

function EditColumn({
    Data,
    selectedRow,
    setlistSelectedData,
    editProfile,
}: ConsultColmun) {
    const [ischeckedEditColmun, setIsCheckedEditColmun] = React.useState(false);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const SelectedProfil = dynamicTableWrapperContext?.rowData?.original;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function EditProfile() {
        if (SelectedProfil.autorisation[selectedRow.id].Modifie === true) {
            if (ischeckedEditColmun === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: false,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: true,
                };
            }
        } else if (ischeckedEditColmun === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: true,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: false,
                };
            }
    }

    function AddProfile() {
        if (ischeckedEditColmun === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Visible,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie: true,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Visible,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie: false,
            };
        }
    }

    return (
        <div className="d-flex align-items-center pfl_icn_enableDisableChange">
            <Switch
                onChange={() => {
                    setIsCheckedEditColmun(!ischeckedEditColmun);
                    if (editProfile) {
                        EditProfile();
                    } else {
                        AddProfile();
                    }
                    setlistSelectedData(Data);
                }}
                checked={
                    // eslint-disable-next-line no-nested-ternary
                    editProfile
                        ? SelectedProfil.autorisation[selectedRow.id]
                              .Modifie === true
                            ? !ischeckedEditColmun
                            : ischeckedEditColmun
                        : ischeckedEditColmun
                }
                offColor="#E30613"
                onColor="#34C38F"
            />
        </div>
    );
}

export default EditColumn;
