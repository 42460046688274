import React from "react";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { DominantColor, DynamicTableWrapperContext } from "@components/Common";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { ActionMultiUserAccount } from "./ActionMultiUserAccount";

type ActionCustonFiltertype = {
    handleSubmit: Function;
    selectedRows: any[];
    handleManageAccessOnClickEvent: Function;
    handleBlockAccountOnClickEvent: Function;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmArchivedModal: boolean;
};
function ActionCustonFilter({
    handleSubmit,
    selectedRows,
    handleManageAccessOnClickEvent,
    handleBlockAccountOnClickEvent,
    setIsConfirmArchivedModal,
    isConfirmArchivedModal,
}: ActionCustonFiltertype) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    return (
        <div className="d-flex flex-row align-items-center ml-2">
            <AvForm onSubmit={handleSubmit}>
                {!dynamicTableWrapperContext?.isActionColumnDisplayed ? (
                    <div className="cmn_btn_openForm">
                        <StyledButtonWithIcon
                            icon="PlusIcon"
                            iconPosition="left"
                            rounded
                            variant={dominantColor}
                            disabled={false}
                        >
                            {t("Add user account")}
                        </StyledButtonWithIcon>
                    </div>
                ) : (
                    <StyledIconButton
                        style={{
                            width: "48px",
                            height: "48px",
                        }}
                        variant={dominantColor}
                        icon="PlusIcon"
                        className="bg-dark ctl_btn_closeForm"
                        rounded
                        disabled={false}
                    >
                        <PlusIcon height={20} width={20} fill="white" />
                    </StyledIconButton>
                )}
            </AvForm>
            <div className="d-flex justify-content-end align-items-end">
                <ActionMultiUserAccount
                    selectedRows={selectedRows}
                    // eslint-disable-next-line react/jsx-no-bind
                    handleManageAccessOnClickEvent={
                        handleManageAccessOnClickEvent
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                    handleBlockAccountOnClickEvent={
                        handleBlockAccountOnClickEvent
                    }
                    setIsConfirmArchivedModal={setIsConfirmArchivedModal}
                    isConfirmArchivedModal={isConfirmArchivedModal}
                />
            </div>
        </div>
    );
}

export default ActionCustonFilter;
