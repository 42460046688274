import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";

import { StatusActive } from "@constants/index";
import { UserAccountList } from "./UserAccountList";
import { AddUser } from "./Adduser/AddUser";

export default function UserAccounts() {
    const { t } = useTranslation();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;
    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Access",
                            link: "#",
                        },
                        {
                            item: t(selectedCustomer),
                            link: "#",
                        },
                        {
                            item: t("User accounts"),
                            link: "#",
                        },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is essential to create accounts for the users of each customer account in order to be able, according to the authorizations of the profile assigned to this user, to access the different ETK modules"
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<UserAccountList />}
                        ActionColumnComponent={
                            <AddUser userStatus={StatusActive} />
                        }
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}
