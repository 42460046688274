import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "reactstrap";
import { DynamicTable } from "@maherunlocker/custom-react-table";
import { PageTitle, Breadcurmb } from "@components/index";

import SelectedCustomerAccountButton from "./SelectedCustomerAccountButton";

import "./index.css";

export default function CustomerAccountListWrapper(): JSX.Element {
    const { t } = useTranslation();
    const uidUserEntity = localStorage.getItem("Uid_Entitie_User");
    // eslint-disable-next-line no-unused-vars
    const [, setData] = React.useState<any>({});
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const arrayOfCustomColumns = [
        {
            indexOFColumn: 99999,
            columnName: "Actions",
            customJsx: SelectedCustomerAccountButton,
        },
    ];
    const isVisible = true;

    return (
        <React.Fragment>
            <PageTitle title="Access" />

            <div className="page-content" style={{ width: "100%" }}>
                <Breadcurmb title={t("Please choose a customer account")} />
                <Card
                    style={{
                        padding: "20px",
                        margin: "9px",
                        height: "654px !important",
                    }}
                >
                    <DynamicTable
                        url={`${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/accounts/${uidUserEntity}`}
                        actionColumn={() => null}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        setData={setData}
                        canResize
                        showGlobalFilter
                        showFilter
                        setLocalFilterActive={setLocalFilterActive}
                        filterActive={filterActive}
                        minHeight={`calc(100vh - (370px + ${
                            isVisible ? "100px" : "0px"
                        }))`}
                        maxHeight={`calc(100vh - (370px + ${
                            isVisible ? "125px" : "0px"
                        }))`}
                    />
                </Card>
            </div>
        </React.Fragment>
    );
}
