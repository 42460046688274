import { PencilIcon } from "@aureskonnect/react-ui";
import { DynamicTableWrapperContext } from "@components/Common";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { ConsultIcon } from "@components/Common/SvgIcons/ConsultIcon";
import React from "react";
import { useTranslation } from "react-i18next";

type customActionType = {
    isProfileListArchived: boolean;
    handleOnEditUser: Function;
    e: any;
};

function CustomJsx({
    isProfileListArchived,
    handleOnEditUser,
    e,
}: customActionType) {
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const { t } = useTranslation();
    return (
        <div className="d-flex justify-content-around w-50">
            {isProfileListArchived === true ? (
                <div id={`Duplication${e?.selectedRow?.iuud}`}>
                    <LockIconWithToolTip
                        message={t(`Consulter`)}
                        id={`Duplication${e?.selectedRow?.iuud}`}
                    />

                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            dynamicTableWrapperContext?.setRowData(
                                e.selectedRow
                            );
                            dynamicTableWrapperContext?.setLocalFilterActive(
                                false
                            );
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                true
                            );
                        }}
                    >
                        <ConsultIcon height={40} width={40} />
                    </div>
                </div>
            ) : (
                <div
                    id={`Modifier${e?.selectedRow?.iuud}`}
                    className={`cmn_icn_edit ${
                        e.selectedRow.isProfileAdmin === true
                            ? "disabled_clz"
                            : ""
                    } `}
                >
                    <LockIconWithToolTip
                        message={t(`Edit`)}
                        id={`Modifier${e?.selectedRow?.iuud}`}
                    />

                    <PencilIcon
                        style={{
                            cursor: "pointer",
                        }}
                        height={25}
                        width={25}
                        onClick={() => {
                            handleOnEditUser(e.selectedRow);
                            dynamicTableWrapperContext?.setLocalFilterActive(
                                false
                            );
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                true
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default CustomJsx;
