import { VerticalDotsIcon } from "@aureskonnect/react-ui";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";

type ArchivedMultiActionType = {
    selectedRows: any[];
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function ArchivedMultiAction({
    selectedRows,
    setIsConfirmUnArchivedModal,
}: ArchivedMultiActionType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    function handleUnarchiveMultiAction(selectedRows: any[]) {
        dynamicTableWrapperContext?.setRowData(selectedRows);
        setIsConfirmUnArchivedModal(true);
    }
    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="com_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    role="button"
                    tabIndex={0}
                    className={`car_btn_unarchiveAccount dropdown-item pl-1 ${
                        selectedRows && selectedRows?.length > 0
                            ? ""
                            : "disabled_clz"
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                       handleUnarchiveMultiAction(selectedRows);
                    }}
                >
                    <UnArchiveIcon width={25} height={25} />
                    <span className="ml-2">{t("UnArchiver account")}</span>
                </div>
            </div>
        </div>
    );
}
export default ArchivedMultiAction;
