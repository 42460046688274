import { createSlice } from "@reduxjs/toolkit";

type dominantColorType = {
    value: { dominantColor: string };
};

const initialState: dominantColorType = { value: { dominantColor: "primary" } };
export const dominantColorSlice = createSlice({
    name: "dominantColor",
    initialState,
    reducers: {
        setDominantColor: (state: any, action: any) => {
            state.value = action.payload;
        },
    },
});

export const { setDominantColor } = dominantColorSlice.actions;

export default dominantColorSlice.reducer;
