import React from "react";

import { useTranslation } from "react-i18next";
import { CheckIcon, StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { mutate } from "swr";

import { DynamicTableWrapperContext, DominantColor } from "@components/Common/";

import { toast } from "react-toastify";

type ConfirmModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function ConfirmBlockModal({
    isOpen,
    setIsOpen,
    setSelectedRows,
}: ConfirmModalPropsType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dominantColor: string = DominantColor();
    const userData = dynamicTableWrapperContext?.rowData?.original;
    let dataCustomerAccount: string = "";

    async function handleBlockUserAccountOnClickEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        // Prevent Multiple Button Clicks
        e.currentTarget.disabled = true;

        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/update-user-status`;
        // Check if single or multi update action & set correspond URL request
        if (dynamicTableWrapperContext?.rowData?.original) {
            dataCustomerAccount += `'${dynamicTableWrapperContext?.rowData?.original.ID}'`;
        } else {
            Object.values(dynamicTableWrapperContext?.rowData).forEach(
                (userItem: any, index: number) => {
                    if (index !== 0) {
                        dataCustomerAccount += `, '${userItem.ID}'`;
                    } else {
                        dataCustomerAccount += `'${userItem.ID}'`;
                    }
                }
            );
        }
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            userAccountStatus: "Bloquer",
                            customerAccountUUid: dataCustomerAccount,
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error(
                                    "Error while blocking user account !"
                                );
                            }
                            toast.success(
                                `${t(
                                    "The user account has been blocked successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                            setValidateOnclick(true);
                            setIsOpen(false);
                            setSelectedRows?.([]);
                        })
                );
            } catch (e: any) {
                toast.error(`${t("Error while blocking user account")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={(): boolean => setIsOpen(!isOpen)} tag="div">
                <span className="text-uppercase cmn_txt_titleAlert">
                    {t("Confirmation")}
                    {dynamicTableWrapperContext?.rowData?.title}
                </span>
            </ModalHeader>
            <ModalBody className="cmn_txt_descriptionAlert">
                {t("Do you want to confirm the blocking of the user account")}{" "}
                {userData?.Nom} {userData?.Prénom}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    className="cmn_btn_validateAlert"
                    onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        handleBlockUserAccountOnClickEvent(e);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
