import crypto from "crypto";

export function decryptWord(wordToDecrypt: string): string {
    if (
        wordToDecrypt === "" ||
        wordToDecrypt === undefined ||
        wordToDecrypt === null
    )
        return "";
    let decryptedWord = wordToDecrypt;
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);
    const buff = Buffer.from(decryptedWord, "base64");
    decryptedWord = buff.toString("utf8");
    const decryptor = crypto.createDecipheriv(
        String("AES256"),
        // String(`${process.env.REACT_APP_Algorithm}`),
        key,
        iv
    );

    return (
        decryptor.update(decryptedWord, "base64", "utf8") +
        decryptor.final("utf8")
    );
}

export function cryptWord(wordToCrypt: string): string {
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);

    const encryptor = crypto.createCipheriv(
        // String(`${process.env.REACT_APP_Algorithm}`),
        String("AES256"),
        key,
        iv
    );
    const aesEncrypted =
        encryptor.update(wordToCrypt, "utf8", "base64") +
        encryptor.final("base64");
    return Buffer.from(aesEncrypted).toString("base64");
}
