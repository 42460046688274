import { combineReducers } from "@reduxjs/toolkit";
import { History } from "history";
import { connectRouter } from "connected-react-router";
import {
    dominantColorSlice,
    layoutSlice,
    editObserverSlice,
    userAccountSlice,
    accessSlice,
} from "./features";
import profileSlice from "./features/profileSlice";

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        dominantColorSlice,
        layoutSlice,
        editObserverSlice,
        userAccountSlice,
        accessSlice,
        profileSlice
    });

export default createRootReducer;
