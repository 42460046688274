import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useDispatch } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { setIsProfileListArchived } from "@store/features/profileSlice";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";

import { AddProfile } from "./ActionProfile/AddProfile";

import { ProfileList } from "./ProfileList";

export default function Profile() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    dispatch(setIsProfileListArchived(false));

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Access",
                            link: "#",
                        },
                        {
                            item: t(selectedCustomer),
                            link: "#",
                        },
                        {
                            item: t("Configuration"),
                            link: "#",
                        },
                        {
                            item: t("Profile"),
                            link: "#",
                        },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is mandatory to create profiles and manage their permissions in order to assign them to users. Switching between active and archived profiles is possible."
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<ProfileList />}
                        ActionColumnComponent={<AddProfile />}
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}
