import { useSelector } from "react-redux";
import { RootState } from "@store/index";

export function DominantColor() {
    const dominantColor: any = useSelector(
        (State: RootState): string => State.dominantColorSlice.value.dominantColor
    );

    return dominantColor;
}
