/* eslint-disable no-redeclare */
import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";

import { ModalContentWrapper } from "./ModalContentWrapper/ModalContentWrapper";

type MangeProfilePermissions = {
    isOpen: boolean;
    setIsOpen: Function;
    handleSaveButtonOnClickEvent: Function;

    setlistSelectedData: Function;
    editProfile: boolean;
};
function MangeProfilePermissions({
    isOpen,
    setIsOpen,
    handleSaveButtonOnClickEvent,
    setlistSelectedData,
    editProfile,
}: MangeProfilePermissions) {
    const { t } = useTranslation();

    function handleCloseModal() {
        setIsOpen(!isOpen);
    }
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const SelectedProfil = dynamicTableWrapperContext?.rowData?.original;
    const msgHeader = editProfile
        ? `${t("PROFILE PERMISSIONS")} : ${SelectedProfil?.Désignation}`
        : t("PROFILE PERMISSIONS");

    return (
        <Modal
            centered
            backdrop="static"
            fade={false}
            scrollable
            isOpen={isOpen}
        >
            <ModalHeader
                toggle={(): any => {
                    handleCloseModal();
                }}
                className="text-uppercase cursor__clz"
            >
                <span
                    className="pfl_txt_profilePermission"
                    style={{
                        textAlign: "left",
                        font: " normal normal bold 20px/14px Segoe UI",
                        color: "#2B2828",
                        fontWeight: 600,
                    }}
                >
                    {msgHeader}
                </span>
            </ModalHeader>
            <ModalBody className="p-0">
                <ModalContentWrapper
                    setlistSelectedData={setlistSelectedData}
                    editProfile={editProfile}
                />
            </ModalBody>
            <ModalFooter>
                <StyledButton
                    className="pfl_btn_validate"
                    rounded
                    variant="primary"
                    onClick={() => {
                        handleSaveButtonOnClickEvent();
                    }}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}

export default MangeProfilePermissions;
