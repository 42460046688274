/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { DynamicTableWrapperContext } from "@components/Common";
import ConfirmArchivedModalProfile from "@components/Common/Modals/ConfirmArchivedModalProfile";
import ConfirmUnArchivedModalProfile from "@components/Common/Modals/ConfirmUnArchivedModalProfile";
import MangeProfilePermissions from "@components/Common/Modals/ManageProfilePermissions/MangeProfilePermissions";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { RootState } from "@store/index";

// eslint-disable-next-line import/no-duplicates
import "./style.css";
import { useSelector } from "react-redux";
import ColumnActive from "./ColumnActive";
import CustomActionFilter from "./CustomActionFilter";
import CustomJsx from "./CustomJsx";

export function ProfileList(): JSX.Element {
    const { t } = useTranslation();

    const isVisible = true;

    const [profileStatus, setProfileStatus] = React.useState("Activé");
    const [accountNumber, setAccountNumber] = React.useState<number>(0);
    const [listSelectedData, setlistSelectedData] = React.useState<any>({});
    const [editProfile, setEditActionProfile] = React.useState<boolean>(false);
    const customerAccountId = localStorage.getItem("customerAccountId");
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const [isManagePermissionsModalOpened, setIsManagePermissionsModalOpened] =
        React.useState<boolean>(false);

    const isProfileListArchived = useSelector(
        (state: RootState) => state.profileSlice.isProfileListArchived
    );

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [isConfirmArchivedModal, setIsConfirmArchivedModal] =
        React.useState<boolean>(false);
    const [isConfirmUnArchivedModal, setIsConfirmUnArchivedModal] =
        React.useState<boolean>(false);

    function handleOnEditUser(profile: any) {
        dynamicTableWrapperContext?.setIsEdit(true);
        dynamicTableWrapperContext?.setRowData({ ...profile });
    }

    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData({
            Désignation: "",
            Balise: "",
            Description: "",
        });

        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    function handleManagePermissionsOnClickEvent(selectedRow: any) {
        if (Object.keys(selectedRow?.original?.autorisation).length > 0) {
            setIsManagePermissionsModalOpened(!isManagePermissionsModalOpened);
            dynamicTableWrapperContext?.setRowData({ ...selectedRow });
            setEditActionProfile(true);
        }
    }

    const DataAutorisation: any = {};

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleEditedProfileButtonOnClickEvent() {
        const profile = dynamicTableWrapperContext?.rowData?.original;
        const keys = Object.keys(listSelectedData);

        const copyData = profile.autorisation;
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/edit-profile?profileStatus=${profileStatus}`;
        Object.values(listSelectedData).forEach((Element: any) => {
            if (keys.includes(Element.IdParent)) {
                if (Element.IdParent === Element.Id) {
                    if (
                        listSelectedData[Element.IdParent].Visible === true &&
                        listSelectedData[Element.Id].Visible === true
                    ) {
                        if (
                            listSelectedData[Element.Id].Consult === false ||
                            listSelectedData[Element.IdParent].Modifie === false
                        ) {
                            listSelectedData[Element.Id].Modifie = false;
                        }
                        DataAutorisation[Element.Id] =
                            listSelectedData[Element.Id];
                    } else if (listSelectedData[Element.Id].Visible === false) {
                        listSelectedData[Element.Id].Consult = false;
                        listSelectedData[Element.Id].Modifie = false;
                        DataAutorisation[Element.Id] =
                            listSelectedData[Element.Id];
                    } else if (
                        listSelectedData[Element.IdParent].Modifie === false
                    ) {
                        listSelectedData[Element.Id].Modifie = false;
                        DataAutorisation[Element.Id] =
                            listSelectedData[Element.Id];
                    }
                } else if (
                    listSelectedData[Element.IdParent].Visible === true &&
                    listSelectedData[Element.IdParent].Consult === true &&
                    listSelectedData[Element.Id].Visible === true
                ) {
                    if (
                        listSelectedData[Element.Id].Consult === false ||
                        listSelectedData[Element.IdParent].Modifie === false
                    ) {
                        listSelectedData[Element.Id].Modifie = false;
                    }
                    DataAutorisation[Element.Id] = listSelectedData[Element.Id];
                }
            } else {
                if (listSelectedData[Element.Id].Visible === true) {
                    if (listSelectedData[Element.Id].Consult === false) {
                        listSelectedData[Element.Id].Modifie = false;
                    }
                } else {
                    listSelectedData[Element.Id].Consult = false;
                    listSelectedData[Element.Id].Modifie = false;
                }
                DataAutorisation[Element.Id] = listSelectedData[Element.Id];
            }
        });
        const keys1 = Object.keys(DataAutorisation);
        Object.values(copyData).forEach((Element1: any) => {
            if (keys1.includes(Element1.Id)) {
                copyData[Element1.Id] = DataAutorisation[Element1.Id];
            }
            // eslint-disable-next-line no-empty
            if (Element1.IdParent === Element1.Id) {
            } else if (
                copyData[Element1.IdParent].Visible === true &&
                copyData[Element1.IdParent].Consult === false
            ) {
                copyData[Element1.Id].Visible = false;
                copyData[Element1.Id].Consult = false;
                copyData[Element1.Id].Modifie = false;
                // eslint-disable-next-line sonarjs/no-duplicated-branches
            } else if (copyData[Element1.IdParent].Visible === false) {
                copyData[Element1.Id].Visible = false;
                copyData[Element1.Id].Consult = false;
                copyData[Element1.Id].Modifie = false;
            } else if (copyData[Element1.IdParent].Modifie === false) {
                copyData[Element1.Id].Modifie = false;
            }
        });
        profile.autorisation = copyData;
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            customerAccountUUid: customerAccountId,
                            data: {
                                ...profile,
                                uid: dynamicTableWrapperContext?.rowData
                                    ?.original?.uid,
                            },
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }

                            toast.success(
                                `${t("The profile is successfully updated")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                            setIsManagePermissionsModalOpened(
                                !isManagePermissionsModalOpened
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 3,
            columnName: "Actions",
            // eslint-disable-next-line react/no-unstable-nested-components
            customJsx: (e: any): JSX.Element => (
                <CustomJsx
                    isProfileListArchived={isProfileListArchived}
                    // eslint-disable-next-line react/jsx-no-bind
                    handleOnEditUser={handleOnEditUser}
                    e={e}
                />
            ),
        },
    ];

    async function listArchiveAccount(state: string) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/profiles?customerAccountUUid=${customerAccountId}&profileStatus=${state}`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((res) => {
                        setAccountNumber(res.data.length);
                    })
            );
        } catch (e) {
            // eslint-disable-next-line
            console.error();
        }
    }

    React.useEffect(() => {
        if (isProfileListArchived === true) {
            setProfileStatus("Archiver");
            listArchiveAccount("Activé");
        } else {
            setProfileStatus("Activé");
            listArchiveAccount("Archiver");
        }
    }, [isProfileListArchived]);

    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/profiles?customerAccountUUid=${customerAccountId}&profileStatus=${profileStatus}`}
                canSort
                name="table-user"
                canResize
                showGlobalFilter
                showFilter
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <ColumnActive
                            isProfileListArchived={isProfileListArchived}
                            isConfirmUnArchivedModal={isConfirmUnArchivedModal}
                            setIsConfirmUnArchivedModal={
                                setIsConfirmUnArchivedModal
                            }
                            setIsConfirmArchivedModal={
                                setIsConfirmArchivedModal
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleManagePermissionsOnClickEvent={
                                handleManagePermissionsOnClickEvent
                            }
                            isConfirmArchivedModal={isConfirmArchivedModal}
                            e={e}
                        />
                    );
                }}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                setData={dynamicTableWrapperContext?.setDataTable}
                customJsxSideFilterButton={
                    <CustomActionFilter
                        isProfileListArchived={isProfileListArchived}
                        handleSubmit={handleSubmit}
                        accountNumber={accountNumber}
                    />
                }
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
            />
            <ConfirmArchivedModalProfile
                isOpen={isConfirmArchivedModal}
                setIsOpen={setIsConfirmArchivedModal}
            />
            <ConfirmUnArchivedModalProfile
                isOpen={isConfirmUnArchivedModal}
                setIsOpen={setIsConfirmUnArchivedModal}
            />
            <MangeProfilePermissions
                isOpen={isManagePermissionsModalOpened}
                setIsOpen={setIsManagePermissionsModalOpened}
                // eslint-disable-next-line react/jsx-no-bind
                handleSaveButtonOnClickEvent={
                    handleEditedProfileButtonOnClickEvent
                }
                setlistSelectedData={setlistSelectedData}
                editProfile={editProfile}
            />
        </React.Fragment>
    );
}
