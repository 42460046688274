import React from "react";
import { motion } from "framer-motion/dist/framer-motion";

import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";

import { DynamicTableWrapper, PageTitle } from "@components/Common";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";

import { ArchivedAccountList } from "./ArchivedAccountList";
import { ConsultUser } from "./ConsultUser";

export default function ArchivedAccounts(): JSX.Element {
    const { t } = useTranslation();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Access",
                            link: "#",
                        },
                        {
                            item: t(selectedCustomer),
                            link: "#",
                        },
                        {
                            item: t("Archived accounts"),
                            link: "#",
                        },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "It is possible to consult the customer accounts archived in this section. Their activation is allowed by declaring a pattern."
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<ArchivedAccountList />}
                        ActionColumnComponent={<ConsultUser />}
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}
