import React from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import i18n from "../../../i18n";

export default function LanguageDropdown(): JSX.Element {
    const [menu, setMenu] = React.useState<boolean>(false);
    const [language, setLanguage] = React.useState<string>(
        i18n.language === "fr" ? "Français" : "English"
    );
    function changeLanguage(currentLanguage: string): void {
        i18n.changeLanguage(currentLanguage === "en" ? "en" : "fr");

        if (currentLanguage === "en") {
            setLanguage("English");
            i18n.changeLanguage("en");
            localStorage.setItem("i18nextLng", "en");
        } else if (currentLanguage === "fr") {
            setLanguage("Français");
            i18n.changeLanguage("fr");
            localStorage.setItem("i18nextLng", "fr");
        }
    }
    return (
        <Dropdown
            isOpen={menu}
            toggle={() => setMenu(!menu)}
            className="d-inline-block"
        >
            <DropdownToggle
                className="btn header-item waves-effect"
                tag="button"
            >
                {language === "Français" ? (
                    <img
                        alt="img"
                        style={{ width: "35px" }}
                        src="https://flagpedia.net/data/flags/h80/fr.webp"
                    />
                ) : (
                    <img
                        alt="img"
                        style={{ width: "40px" }}
                        src="https://flagpedia.net/data/flags/h80/us.webp"
                    />
                )}
            </DropdownToggle>
            <DropdownMenu className="language-switch">
                <DropdownItem
                    tag="a"
                    onClick={() =>
                        changeLanguage(language === "English" ? "fr" : "en")
                    }
                    className={`notify-item ${
                        language === "English" ? "active" : "none"
                    } ${language === "English" ? "d-none" : ""}`}
                >
                    <img
                        alt="img"
                        style={{ width: "40px" }}
                        src="https://flagpedia.net/data/flags/h80/us.webp"
                    />
                </DropdownItem>
                <DropdownItem
                    tag="a"
                    onClick={() => changeLanguage("fr")}
                    className={`notify-item ${
                        language === "Français" ? "active" : "none"
                    } ${language === "Français" ? "d-none" : ""}`}
                >
                    <img
                        alt="img"
                        style={{ width: "35px" }}
                        src="https://flagpedia.net/data/flags/h80/fr.webp"
                    />
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
}
