import { DynamicTable } from "@maherunlocker/custom-react-table";
import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { setUserChildsAccess } from "@store/features/accessSlice";

import HeaderComponent from "@components/VerticalLayout/HeaderComponent";

import AllowAccess from "./AllowAccess";

type ManageAccessModalContentPropsType = {
    userId: string;
};

export function ManageAccessModalContent({
    userId,
}: ManageAccessModalContentPropsType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = React.useState([]);

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: "Autoriser l'accès",
            customJsx: AllowAccess,
        },
    ];

    const customerAccountId = localStorage.getItem("customerAccountId");

    let url = `${process.env.REACT_APP_ACCESS_API_URL}/customer-account-childs?customerAccountUUid=${customerAccountId}`;

    if (userId !== undefined) {
        url += `&userAccountId=${userId}`;
    }

    React.useEffect(() => {
        if (Object.keys(data).length > 0) {
            const localData = (data as any).data!.map((child: any) => {
                return {
                    id: child.id,
                    authorization: child.access_authorization,
                };
            });
            localStorage.setItem("userAccessInfo", JSON.stringify(localData));
            dispatch(setUserChildsAccess(localData));
        }
    }, [data, dispatch]);

    return (
        <React.Fragment>
            <HeaderComponent
                title={t(
                    "A user can only access a customer account if access has been granted to him."
                )}
            />
            <DynamicTable
                url={url}
                actionColumn={() => null}
                arrayOfCustomColumns={arrayOfCustomColumns}
                canResize
                showGlobalFilter
                showFilter
                setData={setData}
            />
        </React.Fragment>
    );
}
