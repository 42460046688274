import React from "react";

import "./app.css";
import { setDominantColor } from "@store/features/dominantColorSlice";
import { useDispatch } from "react-redux";
import Router from "./routes";

export default function App() {
    const dispatch = useDispatch();
    // if (process.env.REACT_APP_IS_SSO_ACTIVE === "false") {
    //     localStorage.setItem(
    //         "Uid_Entitie_User",
    //         "8ecfe5ee-926b-423e-8050-c911dd81c147"
    //     );
    // }
    dispatch(setDominantColor({ dominantColor: "#323333" }));
    return <Router />;
}
