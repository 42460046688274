/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable sonarjs/no-duplicate-string */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
    HeaderWrapper,
    MenuBurgerIcon,
    StyledIconButton,
    StyledH1,
} from "@aureskonnect/react-ui";
import classNames from "classnames";

import { RootState } from "@store/.";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Tooltip } from "@mui/material";

import LanguageDropdown from "@components/CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "@components/CommonForBoth/TopbarDropdown/ProfileMenu";

import { HomeSvgIcon, ShopIconHeader } from "../Common/SvgIcons";

import "./layout.css";

type HeaderPropsType = {
    handleSideBarCollapse: any;
    brand: string;
    showBrand: boolean;
    // eslint-disable-next-line
    customCss?: React.CSSProperties;
};
type BrandPropsType = {
    brand: string;
    redirectionLink: string;
};
function Brand({ brand, redirectionLink }: BrandPropsType) {
    const navigate = useNavigate();
    const selectedAccountId = localStorage.getItem("customerAccountId");

    return (
        <div
            onClick={() =>
                selectedAccountId !== "" ? navigate(redirectionLink) : () => {}
            }
            style={{
                cursor: selectedAccountId !== "" ? "pointer" : "unset",
                width: "274px",
            }}
            role="button"
            tabIndex={0}
        >
            <div className="navbar-brand-box d-none d-sm-flex">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand}
                </StyledH1>
            </div>
            <div className="navbar-brand-box d-block d-sm-none">
                <StyledH1 className="text-white d-flex justify-content-center brand__clz mb-0 p-2">
                    {brand.slice(0, 2)}
                </StyledH1>
            </div>
        </div>
    );
}

export default function Header({
    handleSideBarCollapse,
    brand,
    showBrand,
}: HeaderPropsType): JSX.Element {
    const { t } = useTranslation();
    const collapsed = useSelector(
        (state: RootState) => state.layoutSlice.collapsed
    );
    const location = useLocation();
    const navigate = useNavigate();
    const redirectionLink = "customer-accounts";

    return (
        <HeaderWrapper
            style={{
                gridArea: "header",
                backgroundColor: "#ffffff",
            }}
        >
            <div className="navbar-header">
                <div className="d-flex align-items-center">
                    {(location.pathname !== "/customer-accounts" &&
                        showBrand) ||
                    location.pathname === "/customer-accounts" ? (
                        <Brand
                            brand={brand}
                            redirectionLink={redirectionLink}
                        />
                    ) : null}

                    {location.pathname !== "/customer-accounts" && (
                        <div
                            className={classNames(
                                "d-flex justify-content-center align-items-center",
                                {
                                    "ml-2": collapsed === true,
                                    "ml-3": collapsed === false,
                                    "d-none": "/customer-accounts",
                                }
                            )}
                            style={{ columnGap: 10, cursor: "pointer" }}
                        >
                            <StyledIconButton
                                className="m-0 border-none__clz sid_btn_openClose"
                                icon="CloudIcon"
                            >
                                <MenuBurgerIcon
                                    height={20}
                                    width={20}
                                    onClick={handleSideBarCollapse}
                                />
                            </StyledIconButton>
                        </div>
                    )}
                </div>

                <div
                    className="d-flex align-items-center"
                    style={{ gap: "10px" }}
                >
                    <LanguageDropdown />

                    <Tooltip title="">
                        <div>
                            <StyledIconButton
                                className="m-2 border-none__clz"
                                icon="ShopIcon"
                                onClick={() => {
                                    window.location.replace(
                                        `${process.env.REACT_APP_URL_HOME}`
                                    );
                                }}
                            >
                                <HomeSvgIcon />
                            </StyledIconButton>
                        </div>
                    </Tooltip>
                    <Tooltip title={t("Multi-store display").toString()}>
                        <div
                            onClick={() => {
                                navigate("/customer-accounts");
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            <ShopIconHeader height={25} width={25} />
                        </div>
                        {/* </StyledIconButton> */}
                    </Tooltip>

                    <ProfileMenu />
                </div>
            </div>
        </HeaderWrapper>
    );
}
