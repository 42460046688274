import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import React from "react";
import Switch from "react-switch";

type VisibilityColmun = {
    Data: any;
    selectedRow: any;
    setlistSelectedData: Function;
    editProfile: boolean;
};
function VisibilityColumn({
    Data,
    selectedRow,
    setlistSelectedData,
    editProfile,
}: VisibilityColmun) {
    const [ischeckedVisibility, setIsCheckedVisibility] = React.useState(false);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const SelectedProfil = dynamicTableWrapperContext?.rowData?.original;

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function EditProfile() {
        if (SelectedProfil.autorisation[selectedRow.id].Visible === true) {
            if (ischeckedVisibility === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: false,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: true,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            }
        } else if (ischeckedVisibility === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: true,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: false,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            }
    }

    function AddProfile() {
        if (ischeckedVisibility === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible: true,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Modifie,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible: false,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Modifie,
            };
        }
    }

    return (
        <div className="d-flex align-items-center pfl_icn_enableDisableVisibility">
            <Switch
                onChange={() => {
                    setIsCheckedVisibility(!ischeckedVisibility);
                    if (editProfile) {
                        EditProfile();
                    } else {
                        AddProfile();
                    }
                    setlistSelectedData(Data);
                }}
                checked={
                    // eslint-disable-next-line no-nested-ternary
                    editProfile
                        ? SelectedProfil.autorisation[selectedRow.id]
                              .Visible === true
                            ? !ischeckedVisibility
                            : ischeckedVisibility
                        : ischeckedVisibility
                }
                offColor="#E30613"
                onColor="#34C38F"
            />
        </div>
    );
}

export default VisibilityColumn;
