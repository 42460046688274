import {
    SettingsIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { DynamicTableWrapperContext } from "@components/Common";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { statusArchived } from "@constants/index";
import { toast } from "react-toastify";

type ColumnActiveType = {
    isProfileListArchived: boolean;
    isConfirmUnArchivedModal: boolean;
    isConfirmArchivedModal: boolean;
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleManagePermissionsOnClickEvent: Function;
    e: any;
};

function ColumnActive({
    isProfileListArchived,
    isConfirmUnArchivedModal,
    setIsConfirmUnArchivedModal,
    setIsConfirmArchivedModal,
    handleManagePermissionsOnClickEvent,
    isConfirmArchivedModal,
    e,
}: ColumnActiveType) {
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const customerAccountId = localStorage.getItem("customerAccountId");

    const { t } = useTranslation();

    // Handle UNarchive action for a user
    function handleUnArchiveAction() {
        dynamicTableWrapperContext?.setRowData({
            ...e.selectedRow,
        });
        setIsConfirmUnArchivedModal(!isConfirmUnArchivedModal);
    }

    // Handle manage permission for a user
    function handleManagePermissionsAction() {
        dynamicTableWrapperContext?.setActionColumnDisplay(false);
        handleManagePermissionsOnClickEvent(e.selectedRow);
    }

    // Handle archive action for a user
    async function handleArchiveAction() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/update-profile-status?profileUUid=${e.selectedRow.original.uid}`;

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        profileStatus: statusArchived,
                        customerAccountId,
                    }),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while archiving user account")}!`
                            );
                        }
                        if (
                            data.message ===
                            "Profile related to active user account"
                        ) {
                            throw Error("error");
                        } else {
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                            dynamicTableWrapperContext?.setRowData({
                                ...e.selectedRow,
                            });
                            setIsConfirmArchivedModal(!isConfirmArchivedModal);
                        }
                    })
            );
        } catch (e) {
            toast.error(
                `${t(
                    "Be careful, it is impossible to archive a profile used by a user account."
                )}!`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        }
    }

    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="com_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {isProfileListArchived === true ? (
                    <div
                        role="button"
                        tabIndex={0}
                        className={`dropdown-item pl-1 ${
                            e.selectedRow.original.isProfileAdmin === true
                                ? "disabled_clz"
                                : ""
                        } `}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleUnArchiveAction();
                        }}
                    >
                        <UnArchiveIcon width={25} height={25} />
                        <span className="ml-2">{t("unarchive profile")}</span>
                    </div>
                ) : (
                    <>
                        <div
                            role="button"
                            tabIndex={0}
                            className={`dropdown-item pl-1 pfl_btn_managePermissions ${
                                e.selectedRow.original.isProfileAdmin === true
                                    ? "disabled_clz"
                                    : ""
                            } `}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleManagePermissionsAction();
                            }}
                        >
                            <SettingsIcon width={35} height={25} />
                            <span className="ml-2">
                                {t("Manage permissions")}
                            </span>
                        </div>
                        <div
                            role="button"
                            tabIndex={-1}
                            className={`dropdown-item pl-1 cmn_btn_archived ${
                                e.selectedRow.original.isProfileAdmin === true
                                    ? "disabled_clz"
                                    : ""
                            } `}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleArchiveAction();
                            }}
                        >
                            <TrashIcon
                                width={35}
                                height={26}
                                fill="red"
                                style={{ cursor: "pointer" }}
                            />
                            <span className="ml-2">{t("Archive profile")}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ColumnActive;
