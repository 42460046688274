/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { DominantColor, DynamicTableWrapperContext } from "@components/Common";
import React from "react";
import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { useDispatch } from "react-redux";
import { setIsProfileListArchived } from "@store/features/profileSlice";

type CustomActionFiltertype = {
    isProfileListArchived: boolean;
    accountNumber: any;
    handleSubmit: Function;
};

function CustomActionFilter({
    isProfileListArchived,
    handleSubmit,
    accountNumber,
}: CustomActionFiltertype) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const dominantColor = DominantColor();

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    return (
        <div className="d-flex flex-row align-items-center ml-2">
            {!isProfileListArchived && (
                <AvForm onSubmit={handleSubmit}>
                    {!dynamicTableWrapperContext?.isActionColumnDisplayed ? (
                        <div className="cmn_btn_openForm">
                            <StyledButtonWithIcon
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                variant={dominantColor}
                                disabled={false}
                            >
                                {t("Add profile")}
                            </StyledButtonWithIcon>
                        </div>
                    ) : (
                        <StyledIconButton
                            style={{
                                width: "48px",
                                height: "48px",
                            }}
                            variant={dominantColor}
                            icon="PlusIcon"
                            className="bg-dark pfl_btn_closeForm"
                            rounded
                            disabled={false}
                        >
                            <PlusIcon height={20} width={20} fill="white" />
                        </StyledIconButton>
                    )}
                </AvForm>
            )}
            <div className=" dropdown d-flex justify-content-end align-items-end">
                <VerticalDotsIcon
                    className="cmn_drp_menu"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    style={{ cursor: "pointer" }}
                    height={25}
                    width={25}
                    fill="black"
                />

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <div
                        className="dropdown-item pl-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            dispatch(
                                setIsProfileListArchived(!isProfileListArchived)
                            );
                        }}
                    >
                        {isProfileListArchived === false ? (
                            <span className="ml-2 pfl_btn_archivedProfile">
                                {`${t(
                                    `Archived profile(s)`
                                )}(${accountNumber})`}
                            </span>
                        ) : (
                            <span className="ml-2">
                                {`${t(
                                    `Profile(s) activated`
                                )} (${accountNumber})`}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CustomActionFilter;
