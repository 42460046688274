import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { mutate } from "swr";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton, CheckIcon } from "@aureskonnect/react-ui";

import { RootState } from "@store/index";

import { toast } from "react-toastify";

import { DynamicTableWrapperContext } from "@components/Common";

import { ManageAccessModalContent } from "./ManageAccessModalContent";
import ConfirmAccessModification from "./ConfirmAccessModification";

import "../index.css";

type ManageAccessModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    handleSaveUserButtonOnClickEvent?: Function;
    user?: any;
};

export function ManageAccessModal({
    isOpen,
    setIsOpen,
    handleSaveUserButtonOnClickEvent,
    user,
}: ManageAccessModalPropsType) {
    const { t } = useTranslation();

    const access = useSelector((state: RootState) => state.accessSlice.access);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [isConfirmAccessModificationOpened, setIsConfirmAccessModification] =
        React.useState(false);
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);

    const userData = dynamicTableWrapperContext?.rowData?.original;

    const userInfo = {
        ...user,
        access_authorization: [...access],
    };

    async function handleManageAcesstOnClickEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        // Prevent Multiple Button Clicks
        e.currentTarget.disabled = true;

        if (userData === undefined) {
            handleSaveUserButtonOnClickEvent !== undefined &&
                handleSaveUserButtonOnClickEvent(userInfo);
        } else {
            const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/update-user-access?userAccountUUid=${userData?.ID}`;
            if (validateOnclick) {
                setValidateOnclick(false);
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                access_authorization: access,
                            }),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while blocking user account !"
                                    );
                                }

                                toast.success(
                                    `${t("Changes made successfully")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );

                                dynamicTableWrapperContext?.setDataUpdated(
                                    true
                                );
                                dynamicTableWrapperContext?.setActionColumnDisplay(
                                    false
                                );
                                setValidateOnclick(true);
                                setIsOpen(false);
                            })
                    );
                } catch (e: any) {
                    toast.error(`${t("Error while blocking user account")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
        if (isConfirmAccessModificationOpened === true) {
            setIsConfirmAccessModification(false);
        }
    }

    function handleCloseModal() {
        handleSaveUserButtonOnClickEvent !== undefined &&
            handleSaveUserButtonOnClickEvent(userInfo);
        setIsOpen(!isOpen);
    }

    return (
        <React.Fragment>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isOpen}
            >
                <ModalHeader
                    toggle={(): any => {
                        JSON.stringify(access) !==
                        localStorage.getItem("userAccessInfo")
                            ? setIsConfirmAccessModification(
                                  !isConfirmAccessModificationOpened
                              )
                            : handleCloseModal();
                    }}
                    className="text-uppercase cursor__clz"
                >
                    <span
                        style={{
                            textAlign: "left",
                            font: " normal normal bold 20px/14px Segoe UI",
                            color: "#2B2828",
                            fontWeight: 600,
                        }}
                    >
                        {t("GESTION DES ACCÈS AUX COMPTES")}
                    </span>
                </ModalHeader>
                <ModalBody className="p-0">
                    <ManageAccessModalContent userId={userData?.ID} />
                </ModalBody>
                <ModalFooter>
                    <StyledButton
                        rounded
                        variant="primary"
                        onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleManageAcesstOnClickEvent(e);
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
            <ConfirmAccessModification
                isOpen={isConfirmAccessModificationOpened}
                setIsOpen={setIsConfirmAccessModification}
                // eslint-disable-next-line react/jsx-no-bind
                handleManageAcesstOnClickEvent={handleManageAcesstOnClickEvent}
                user={userInfo}
                handleSaveUserButtonOnClickEvent={
                    handleSaveUserButtonOnClickEvent
                }
                closeFirstModal={() => {
                    setIsOpen(!isOpen);
                }}
            />
        </React.Fragment>
    );
}
