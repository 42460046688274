import React from "react";

import ReactSwitch from "react-switch";
import { updateUserChildsAccess } from "@store/features/accessSlice";
import { useDispatch } from "react-redux";

export default function AllowAccess({ selectedRow }: any) {
    const dispatch = useDispatch();
    const [isUserAuthenticated, setIsUserAuthenticated] = React.useState(
        selectedRow.access_authorization
    );

    function handleSwitchOnClickEvent() {
        setIsUserAuthenticated(!isUserAuthenticated);
        dispatch(
            updateUserChildsAccess({
                userAccessStatus: !isUserAuthenticated,
                entityChildId: selectedRow.id,
            })
        );
    }

    return (
        <ReactSwitch
            //  disabled={e?.selectedRow.Visible === false ? true : false}
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={26}
            offColor="#f7b4b8"
            offHandleColor="#E30613"
            checked={isUserAuthenticated}
            onChange={() => {
                handleSwitchOnClickEvent();
            }}
            onColor="#c2eddd"
            onHandleColor="#34C38F"
            width={50}
            height={20}
        />
    );
}
