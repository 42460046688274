import { configureStore } from "@reduxjs/toolkit";

import { createBrowserHistory } from "history";
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import createRootReducer from "./reducers";

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    blacklist: [
        "multipleEditSlice",
        "franchiseNameSlice",
        "editObserverSlice",
        "headerCompoHeighSlice",
        "selectedCardListSlice",
    ],
};

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(createBrowserHistory())
);

const store = configureStore({
    reducer: persistedReducer,

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }),
});

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export default store;
