/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";

import { DynamicTable } from "@maherunlocker/custom-react-table";
import { useTranslation } from "react-i18next";

import ConfirmUnArchivedModal from "@components/Common/Modals/ConfirmUnArchivedModal";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { DynamicTableWrapperContext } from "@components/Common";

import { statusArchived } from "@constants/index";
import { ConsultIcon } from "@components/Common/SvgIcons/ConsultIcon";

import "../UserAccounts/style.css";
import ArchivedMultiAction from "./ArchivedMultiAction";
import ArchivedActionColumn from "./ArchivedActionColumn";

export function ArchivedAccountList(): JSX.Element {
    const { t } = useTranslation();

    const isVisible = true;
    const customerAccountId = localStorage.getItem("customerAccountId");

    const userAccountStatus = statusArchived;
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [isConfirmUnArchivedModal, setIsConfirmUnArchivedModal] =
        React.useState<boolean>(false);

    const customJsxActions = (e: any) => (
        <div className="car_icn_consult d-flex justify-content-around w-50">
            <div id={`Duplication${e?.selectedRow?.iuud}`}>
                <LockIconWithToolTip
                    message={t(`Consulter`)}
                    id={`Duplication${e?.selectedRow?.iuud}`}
                />

                <div
                    onClick={() => {
                        dynamicTableWrapperContext?.setRowData(e.selectedRow);
                        dynamicTableWrapperContext?.setLocalFilterActive(false);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            true
                        );
                    }}
                >
                    <ConsultIcon height={40} width={40} />
                </div>
            </div>
        </div>
    );

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 99,
            columnName: "Actions",
            customJsx: customJsxActions,
        },
    ];

    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/users?customerAccountUUid=${customerAccountId}&userAccountStatus=${userAccountStatus}`}
                canSort
                canSelect
                customSelect
                setSelectedRows={setSelectedRows}
                name="table-user-Archived"
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <ArchivedActionColumn
                            e={e}
                            setIsConfirmUnArchivedModal={
                                setIsConfirmUnArchivedModal
                            }
                            isConfirmUnArchivedModal={isConfirmUnArchivedModal}
                        />
                    );
                }}
                customJsxSideFilterButton={
                    <div className="d-flex flex-row align-items-center ml-2">
                        <div className="d-flex justify-content-end align-items-end">
                            <ArchivedMultiAction
                                selectedRows={selectedRows}
                                setIsConfirmUnArchivedModal={
                                    setIsConfirmUnArchivedModal
                                }
                            />
                        </div>
                    </div>
                }
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                canResize
                showGlobalFilter
                showFilter
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
            />

            <ConfirmUnArchivedModal
                isOpen={isConfirmUnArchivedModal}
                setIsOpen={setIsConfirmUnArchivedModal}
            />
        </React.Fragment>
    );
}
