import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import { StatusBloquer } from "@constants/index";
import { AddUser } from "../UserAccounts/Adduser/AddUser";
import { BlockedAccountList } from "./BlockedAccountList";

function BlockedAccounts() {
    const { t } = useTranslation();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <StyledBreadcrumb
                    items={[
                        {
                            item: "Access",
                            link: "#",
                        },
                        {
                            item: t(selectedCustomer),
                            link: "#",
                        },
                        {
                            item: t("Blocked accounts").toUpperCase(),
                            link: "#",
                        },
                    ]}
                />
                <Container fluid>
                    <HeaderComponent
                        title={t(
                            "Blocked user accounts are grouped in this section. It is possible to activate or archive them following the declaration of a pattern."
                        )}
                    />
                    <DynamicTableWrapper
                        TableComponent={<BlockedAccountList />}
                        ActionColumnComponent={
                            <AddUser userStatus={StatusBloquer} />
                        }
                    />
                </Container>
            </motion.div>
        </React.Fragment>
    );
}

export default BlockedAccounts;
