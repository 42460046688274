/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";

import { DominantColor } from "@components/Common";

export default function SelectedCustomerAccountButton({ selectedRow }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dominantColor: string = DominantColor();

    return (
        <StyledButton
            outline
            rounded
            variant={dominantColor}
            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                localStorage.setItem("customerAccountId", selectedRow.uid);

                localStorage.setItem(
                    "TypeAccount",
                    selectedRow["Type du compte"]
                );

                localStorage.setItem(
                    "customerAccountName",
                    selectedRow["Nom commercial"]
                );

                const selectedCustomerCodeUrl = await fetch(
                    `https://demo-setting-api.aureskonnect.com/api/v1/settings/pays`
                );

                const countryCode: any = await selectedCustomerCodeUrl.json();

                if (countryCode.data !== undefined) {
                    const selectedCountryCode = countryCode.data.find(
                        (data: any) =>
                            data.Designation.toLowerCase() ===
                            selectedRow.Pays.toLowerCase()
                    );

                    localStorage.setItem(
                        "selectedCountryCode",
                        selectedCountryCode.code.toLowerCase()
                    );
                } else {
                    localStorage.setItem("selectedCountryCode", "fr");
                }

                // eslint-disable-next-line no-lone-blocks
                navigate("/users-accounts");
            }}
            style={{ minHeight: "20px", maxHeight: "37px", minWidth: "136px" }}
        >
            {t("To access")}
        </StyledButton>
    );
}
