import {
    DocumentIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { UnlockIcon } from "@components/Common/SvgIcons/UnlockIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";

type BlocMultiActionColumnType = {
    selectedRows: any[];
    setIsConfirmBlockModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function BlocMultiActionColumn({
    selectedRows,
    setIsConfirmBlockModal,
    setIsConfirmArchivedModal,
}: BlocMultiActionColumnType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    function handleMultiUnblockAction(selectedRows: any[]) {
        setIsConfirmBlockModal(true);
        dynamicTableWrapperContext?.setRowData(selectedRows);
    }

    function handleMultiArchiverAction(selectedRows: any[]) {
        setIsConfirmArchivedModal(true);
        dynamicTableWrapperContext?.setRowData(selectedRows);
    }

    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="com_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* Display Account History */}
                <div
                    role="button"
                    tabIndex={-2}
                    className={`dropdown-item pl-1 ctl_btn_blockAccount  ${
                        selectedRows && selectedRows?.length > 0
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {}}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                {/* UNBLOCK user account from Blocked List */}
                <div
                    role="button"
                    tabIndex={-1}
                    className={`cbl_btn_unlockAccount d-flex  align-items-center  dropdown-item pl-1 ${
                        selectedRows && selectedRows?.length > 0
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiUnblockAction(selectedRows);
                    }}
                >
                    <UnlockIcon width={45} height={39} />
                    <span>{t("UnBlock account")}</span>
                </div>
                {/* ARCHIVE User Account on Blocked User List */}
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item d-flex  align-items-center pl-1 cmn_btn_archived ${
                        selectedRows && selectedRows?.length > 0
                            ? ""
                            : "disabled_clz"
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiArchiverAction(selectedRows);
                    }}
                >
                    <TrashIcon
                        width={45}
                        height={26}
                        fill="red"
                        style={{ cursor: "pointer" }}
                    />
                    <span>{t("Archive account")}</span>
                </div>
            </div>
        </div>
    );
}
export default BlocMultiActionColumn;
