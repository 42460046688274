import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton, CheckboxIcon } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { mutate } from "swr";

import { DynamicTableWrapperContext, DominantColor } from "@components/Common/";

import { toast } from "react-toastify";
import { statusArchived } from "@constants/index";

type ConfirmModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
};

export default function ConfirmArchivedModalProfile({
    isOpen,
    setIsOpen,
}: ConfirmModalPropsType) {
    const { t } = useTranslation();
    const customerAccountId = localStorage.getItem("customerAccountId");
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dominantColor: string = DominantColor();
    const profileData = dynamicTableWrapperContext?.rowData?.original;

    async function handleArchivedUserAccountOnClickEvent(
        e: React.ChangeEvent<HTMLInputElement>
    ) {
        // Prevent Multiple Button Clicks
        e.currentTarget.disabled = true;

        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/update-profile-status?profileUUid=${profileData.uid}`;
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            profileStatus: statusArchived,
                            customerAccountId,
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error(
                                    `${t(
                                        "Error while archiving user account"
                                    )}!`
                                );
                            }

                            toast.success(
                                `${t(
                                    "The profile has been archived successfully"
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setValidateOnclick(true);
                            setIsOpen(false);
                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        })
                );
            } catch (e: any) {
                toast.error(
                    `${t(
                        "Be careful, it is impossible to archive a profile used by a user account."
                    )}!`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        }
    }

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={(): boolean => setIsOpen(!isOpen)}>
                <span className="text-uppercase cmn_txt_titleAlert">
                    {t("Confirmation")}
                    {dynamicTableWrapperContext?.rowData?.title}
                </span>
            </ModalHeader>
            <ModalBody className="cmn_txt_descriptionAlert">
                {`${t("Do you want to confirm the archiving of the profile")} ${
                    profileData?.Désignation
                } `}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    className="cmn_btn_validateAlert"
                    onClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleArchivedUserAccountOnClickEvent(e)
                    }
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
