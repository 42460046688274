/* eslint-disable sonarjs/no-collapsible-if */
import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { StyledButton, CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";

import { Card, CardFooter } from "reactstrap";

import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";

import "react-phone-number-input/style.css";

import { mutate } from "swr";
import {
    DominantColor,
    DynamicTableWrapperContext,
    RequirementToast,
} from "@components/Common";
import { ManageAccessModal } from "@components/Common/Modals/AccessManagment/ManageAccessModal";
import { TYPEBoutique } from "@constants/index";
import { FormBody } from "./FormBody";

import "../style.css";

type typeAdduser = {
    // eslint-disable-next-line react/no-unused-prop-types
    userStatus: any;
};

export function AddUser({ userStatus }: typeAdduser): JSX.Element {
    const { t } = useTranslation();
    const tostEmailmsg = "The field ''Email'' must be filled correctly";
    const refs = React.useRef<HTMLDivElement[]>([]);
    const dominantColor: string = DominantColor();
    const customerAccountId = localStorage.getItem("customerAccountId");
    const TypeAccount = localStorage.getItem("TypeAccount");
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [isTelephoneEmptyField, setIsTelephoneEmptyField] =
        React.useState<boolean>(false);
    const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false);
    const [isInvalidTelephone] = React.useState<boolean>(false);
    const [onTelephoneFocus, setOnTelephoneFocus] =
        React.useState<boolean>(false);
    const [user, setUser] = React.useState<Usertype>({
        Nom: "",
        Prénom: "",
        Email: "",
        "Téléphone 1": "",
        "Téléphone 2": "",
        Profil: "",
        Balise: "",
        Commentaire: "",
        access_authorization: [],
    });

    const [verifyEmailError, setVerifyEmailError] =
        React.useState<boolean>(false);

    const addToRefs = (el: HTMLDivElement) => {
        if (el && !refs?.current?.includes(el)) {
            refs?.current?.push(el);
        }
    };
    const [isManageAccessModalOpened, setIsManageAccessModalOpened] =
        React.useState<boolean>(false);

    React.useEffect((): void => {
        if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
            setUser({
                Nom: dynamicTableWrapperContext?.rowData?.Nom,
                Prénom: dynamicTableWrapperContext?.rowData?.Prénom,
                Email: dynamicTableWrapperContext?.rowData?.Email,
                "Téléphone 1":
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    dynamicTableWrapperContext?.rowData?.["Téléphone 1"],
                "Téléphone 2":
                    dynamicTableWrapperContext?.rowData?.["Téléphone 2"],
                Profil: dynamicTableWrapperContext?.rowData?.Profil,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Commentaire: dynamicTableWrapperContext?.rowData?.Commentaire,
                access_authorization:
                    dynamicTableWrapperContext?.rowData?.access_authorization,
                selectedProfil:
                    dynamicTableWrapperContext?.rowData?.selectedProfil,
            });
        }
    }, [dynamicTableWrapperContext?.rowData]);

    async function handleSaveUserButtonOnClickEvent(userInfo: any) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/add-user`;
        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            customerAccountUUid: customerAccountId,
                            data: userInfo,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setValidateOnclick(true);
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }

                            toast.success(
                                `${t("The user is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );

                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }

    async function handleEditedUserButtonOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/edit-user?userAccountStatus=${userStatus}`;

        if (
            user.Email === "" ||
            verifyEmailError === true ||
            isInvalidEmail === true
        ) {
            RequirementToast(t(tostEmailmsg));
        } else if (user["Téléphone 1"] === "") {
            setIsTelephoneEmptyField(true);
            setOnTelephoneFocus(true);
            RequirementToast(t("The field ''Téléphone 1'' must be filled"));
        } else {
            // eslint-disable-next-line no-lonely-if
            if (validateOnclick) {
                setValidateOnclick(false);
                try {
                    mutate(
                        apiUrl,
                        await fetch(apiUrl, {
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                customerAccountUUid: customerAccountId,
                                data: {
                                    ...user,
                                    ID: dynamicTableWrapperContext?.rowData?.ID,
                                },
                            }),
                            method: "PUT",
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.error) {
                                    throw Error(
                                        "Error while saving selection!"
                                    );
                                }
                                setValidateOnclick(true);
                                toast.success(
                                    `${t("The user is successfully updated")!}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        icon: (
                                            <CheckboxIcon
                                                height={25}
                                                width={25}
                                                fill="white"
                                            />
                                        ),
                                    }
                                );

                                dynamicTableWrapperContext?.setDataUpdated(
                                    true
                                );
                                dynamicTableWrapperContext?.setActionColumnDisplay(
                                    false
                                );
                            })
                    );
                } catch (e: any) {
                    toast.error(`${t("There's an error")}!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
            }
        }
    }

    const handleRefreshIconClick = (): void => {
        if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
            setUser({
                Nom: dynamicTableWrapperContext?.rowData?.Nom,
                Prénom: dynamicTableWrapperContext?.rowData?.Prénom,
                Email: dynamicTableWrapperContext?.rowData?.Email,
                "Téléphone 1":
                    dynamicTableWrapperContext?.rowData?.["Téléphone 1"],
                "Téléphone 2":
                    dynamicTableWrapperContext?.rowData?.["Téléphone 2"],
                Profil: dynamicTableWrapperContext?.rowData?.Profil,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Commentaire: dynamicTableWrapperContext?.rowData?.Commentaire,
                access_authorization:
                    dynamicTableWrapperContext?.rowData?.access_authorization,
                selectedProfil:
                    dynamicTableWrapperContext?.rowData?.selectedProfil,
            });
        } else {
            setUser({
                Nom: "",
                Prénom: "",
                Email: "",
                "Téléphone 1": "",
                "Téléphone 2": "",
                Profil: "",
                Balise: "",
                Commentaire: "",
                access_authorization: [],
                selectedProfil: {},
            });
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const handleOnscroll = (): void => {
        if (refs) {
            refs?.current?.forEach((elm: any) => {
                if (user.Nom === "") {
                    if (elm.id === "Nom") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''Last name'' field must be filled")
                        );
                    }
                } else if (user.Prénom === "") {
                    if (elm.id === "Prénom") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''First name'' field must be filled")
                        );
                    }
                } else if (user.Email === "") {
                    if (elm.id === "Email") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The field ''Email'' must be filled")
                        );
                    }
                }
            });
        }
    };

    const onValidSubmit = (): void => {
        if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-z]{2,4}$/.test(user.Email)) {
            setIsInvalidEmail(true);
            RequirementToast(t(tostEmailmsg));
        } else if (user.Email === "" || isInvalidEmail === true) {
            RequirementToast(t(tostEmailmsg));
        } else if (verifyEmailError === true) {
            RequirementToast(t("This email address is already in use"));
        } else if (user["Téléphone 1"].length <= 3) {
            setIsTelephoneEmptyField(true);
            setOnTelephoneFocus(true);
            RequirementToast(t("The field ''Téléphone 1'' must be filled"));
        } else if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
            handleEditedUserButtonOnClickEvent();
        } else if (TypeAccount !== TYPEBoutique) {
            setIsManageAccessModalOpened(!isManageAccessModalOpened);
        } else {
            handleSaveUserButtonOnClickEvent(user);
        }
    };

    React.useEffect(() => {
        if (user["Téléphone 1"] !== "") {
            setIsTelephoneEmptyField(false);
        }
    }, [user]);

    return (
        <React.Fragment>
            <Card style={{ border: "0px " }}>
                <StyleCardHeader
                    title={
                        dynamicTableWrapperContext?.rowData?.Nom !== ""
                            ? t("Edit user account")
                            : t("Add user account")
                    }
                    refreshIconFunction={handleRefreshIconClick}
                />

                <AvForm
                    onValidSubmit={() => {
                        onValidSubmit();
                    }}
                    onInvalidSubmit={() => {
                        handleOnscroll();
                    }}
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                >
                    <FormBody
                        user={user}
                        setUser={setUser}
                        addToRefs={addToRefs}
                        isTelephoneEmptyField={isTelephoneEmptyField}
                        onTelephoneFocus={onTelephoneFocus}
                        isInvalidTelephone={isInvalidTelephone}
                        isInvalidEmail={isInvalidEmail}
                        setIsInvalidEmail={setIsInvalidEmail}
                        verifyEmailError={verifyEmailError}
                        setVerifyEmailError={setVerifyEmailError}
                    />

                    <CardFooter className="Footercard p-0 ">
                        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                            <div className="p-2 bd-highlight">
                                <StyledButton
                                    rounded
                                    variant={dominantColor}
                                    className="ctl_btn_validate"
                                >
                                    {t("Validate")}
                                </StyledButton>
                            </div>
                            <div className="p-2 bd-highlight ">
                                <StyledButton
                                    className="ctl_btn_cancel"
                                    rounded
                                    outline
                                    variant="light"
                                    onClick={() => {
                                        dynamicTableWrapperContext?.setActionColumnDisplay(
                                            false
                                        );
                                    }}
                                >
                                    {t("Cancel")}
                                </StyledButton>
                            </div>
                        </div>
                    </CardFooter>
                </AvForm>
            </Card>
            <ManageAccessModal
                isOpen={isManageAccessModalOpened}
                setIsOpen={setIsManageAccessModalOpened}
                // eslint-disable-next-line
                handleSaveUserButtonOnClickEvent={
                    handleSaveUserButtonOnClickEvent
                }
                user={user}
            />
        </React.Fragment>
    );
}
